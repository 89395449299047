/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from '@emotion/styled'
import { Parallax } from "react-spring/renderprops-addons.cjs"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import SVG from "@lekoarts/gatsby-theme-cara/src/components/svg"
import { UpDown, UpDownWide } from "@lekoarts/gatsby-theme-cara/src/styles/animations"
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"
import SEO from "@lekoarts/gatsby-theme-cara/src/components/seo"
import React, { useState, useEffect }  from "react"
import { window, exists } from "browser-monads"
import { navigate } from 'gatsby-link'
import axios from "axios"
import { Link } from "gatsby"
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from "gatsby"

const Input = styled.input` 
  background: #242b3c;
    border: 0px;
    font-size: 1em;
    padding:10px;
    margin:5px;
    color:white;
`

const SubmitButton = styled.button`
  border: 0px;
    padding: 10px;
    background: #3caf7b;
    color: white;
    font-size: 1em;
    font-weight: 800;
`   
const LongArrowLeft = styled.div`
  display: block;
  margin: 30px auto;
  width: 25px;
  height: 25px;
  border-top: 2px solid gray;
  border-left: 2px solid gray ;
  transform: rotate(-45deg);
     &:after{
    content: "";
    display: block;
    width: 2px;
    height: 45px;
    background-color: gray;
    transform: rotate(-45deg) translate(15px, 4px);
    left: 0;
    top: 0;
  }
`

const ImageWrapper = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  align-items:center;
  align-content:space-around;
  flex-direction:row;
  background: linear-gradient(45deg, #ffffff,#efefefdb);
  padding:10px;
  border-radius:3px;
  margin-top:20px;
`

const ecommerce = () => {

  useEffect(() => {
        window.scrollTo(0,0)
    })

const data = useStaticQuery(graphql`
query {
  image1: file(relativePath: {eq: "ecommerce/apple_dev.png"}) {
    childImageSharp {
      fluid(maxWidth:200){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image2: file(relativePath: {eq: "ecommerce/appStore.png"}) {
    childImageSharp {
      fluid(maxWidth:150){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image3: file(relativePath: {eq: "ecommerce/google_ads.png"}) {
    childImageSharp {
      fluid(maxWidth:180){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image4: file(relativePath: {eq: "ecommerce/googledev.png"}) {
    childImageSharp {
      fluid(maxWidth:150){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image5: file(relativePath: {eq: "ecommerce/thcsd.png"}) {
    childImageSharp {
      fluid(maxWidth:150){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image6: file(relativePath: {eq: "ecommerce/treezlogo.png"}) {
    childImageSharp {
      fluid(maxWidth:150){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image7: file(relativePath: {eq: "ecommerce/woocommerce.png"}) {
    childImageSharp {
      fluid(maxWidth:150){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image8: file(relativePath: {eq: "ecommerce/wordpress.png"}) {
    childImageSharp {
      fluid(maxWidth:150){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  }
}`
)

  const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });
    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        form.reset();
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://getform.io/f/33fc9681-937d-40a3-9776-38ff436db3db",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Thanks!", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };

    const {pages, contentOffset, contentFactor}  = useWindowWidth(); 
    
   
        return (
       <Layout>

    <Parallax pages={pages} >

    
      <Divider  speed={-0.2}
      offset={0.15}
      factor={1}  bg="linear-gradient(to right, Maroon 0%, Brown 100%)"
      sx={{ clipPath: `polygon(0 15%, 100% 25%, 100% 85%, 0 75%)` }}/>

      <Divider speed={0.2} offset={0.4} factor={1}>
      <UpDown>
        <SVG icon="triangle" hiddenMobile width={48} stroke color="icon_orange" left="10%" top="20%" />
        <SVG icon="hexa" width={48} stroke color="icon_red" left="60%" top="70%" />
        <SVG icon="box" width={6} color="icon_darker" left="60%" top="15%" />
      </UpDown>
      <UpDownWide>
        <SVG icon="arrowUp" hiddenMobile width={16} color="icon_blue" left="80%" top="10%" />
        <SVG icon="triangle" width={12} stroke color="icon_brightest" left="90%" top="50%" />
        <SVG icon="circle" width={16} color="icon_darker" left="70%" top="90%" />
        <SVG icon="triangle" width={16} stroke color="icon_darkest" left="30%" top="65%" />
        <SVG icon="cross" width={16} stroke color="icon_pink" left="28%" top="15%" />
        <SVG icon="circle" width={6} color="icon_darkest" left="75%" top="10%" />
        <SVG icon="upDown" hiddenMobile width={8} color="icon_darkest" left="45%" top="10%" />
      </UpDownWide>
      <SVG icon="circle" hiddenMobile width={24} color="icon_darker" left="5%" top="70%" />
      <SVG icon="circle" width={6} color="icon_darkest" left="4%" top="20%" />
      <SVG icon="circle" width={12} color="icon_darkest" left="50%" top="60%" />
      <SVG icon="upDown" width={8} color="icon_darkest" left="95%" top="90%" />
      <SVG icon="upDown" hiddenMobile width={24} color="icon_darker" left="40%" top="80%" />
      <SVG icon="triangle" width={8} stroke color="icon_darker" left="25%" top="5%" />
      <SVG icon="circle" width={64} color="icon_green" left="95%" top="5%" />
      <SVG icon="box" hiddenMobile width={64} color="icon_purple" left="5%" top="90%" />
      <SVG icon="box" width={6} color="icon_darkest" left="10%" top="10%" />
      <SVG icon="box" width={12} color="icon_darkest" left="40%" top="30%" />
      <SVG icon="hexa" width={16} stroke color="icon_darker" left="10%" top="50%" />
      <SVG icon="hexa" width={8} stroke color="icon_darker" left="80%" top="70%" />
    </Divider>

    <Content offset={contentOffset} factor={contentFactor}>


    
   <div>
        <Inner>
        <Link to="/">
          <LongArrowLeft></LongArrowLeft>
          <h5 style={{textAlign:`center`}}>Go Home</h5>
        </Link>
        <h1  sx={{
          color: "heading",
          m: 1,
        }}>E-COMMERCE, GOOGLE ADS & ANALYTICS</h1>

        <h2>Solution</h2>
        </Inner>
       
        <div>
         <p>
       E-commerce is becoming ever more important to the survival of brick & mortar businesses.</p>

<p>
Developing a connected retail solution that attracts & enables online revenue is now a necessity for anyone needing a sustainable sales strategy.  
 
</p>
<p>
With our expertise in WordPress and WooCommerce, we specialize in interfacing with existing digital infrastructures (CMS, LMS, POS) to create engagement & monetization platforms consisting of online shopping, menu boards, blog content & Google Ads services.
</p>

<p>
Our successes include an 800% increase in online sales over an 18 month period with one of our brick & mortar retailers, taking their sales from $30K per month to $240K per month through the use of our full marketing suite.
</p>

<p>
Through a collection of web development, digital advertising & organic blog traffic, online sales increased as a percentage of total monthly sales from 3.4% to 24% of total monthly sales.
</p>

<p>
Monthly programs start at $599 and include hosting, support & maintenance services. 
</p> 
      </div>

      <div>
        <h2>Useful to</h2>
        <p>
            Cannabis Dispensaries
        </p>

      </div>

       <div id="ttm-form">
                <h2>Get Game Catalog</h2>
                
                <div>
                    <form name="ecommerce" onSubmit={handleOnSubmit}>


                        <Input
                            type="text"
                            placeholder="name"
                            name="name"
                            required
                        />
                        <br/>
                        <Input
                            type="email"
                            placeholder="email"
                            name="email"
                            required
                        />
                        <br />
                        <Input 
                          type="tel"
                          placeholder="phone" 
                          name="phone" 
                        />
                        <br />
                         <Input 
                          type="text"
                          placeholder="company" 
                          name="company" 
                        />
                        <br />
                        <br />
                        <b>Follow up with you?</b>
                        <ul style={{listStyleType:`none`}}>
                          <li>
                                <Input
                                  required
                                    type="radio"
                                    value="Yes"
                                    name="FOLLOWUP"
                                />
                                <label>Yes</label>
                                </li>

                               <li>
                                <Input
                                  required
                                    type="radio"
                                    value="No"
                                    name="FOLLOWUP"
                                /> 
                                <label>No</label>
                                </li>
                        </ul>                                                    
                         <SubmitButton type="submit" disabled={serverState.submitting}> Send </SubmitButton>
                        {serverState.status && (
                        <p className={!serverState.status.ok ? "errorMsg" : ""}>
                        {serverState.status.msg}
                        </p>
                        )}
                    </form>
                </div>
            </div>

             <ImageWrapper>
                       <Img
                        fluid={data.image1.childImageSharp.fluid}
                        alt="apple developer"
                        style={{ width:`100%`}} 
                      />

                      <Img
                        fluid={data.image3.childImageSharp.fluid}
                        alt="google ads" 
                        style={{ width:`100%`}}
                      />
                      <Img
                        fluid={data.image4.childImageSharp.fluid}
                        alt="sca logo" 
                        style={{ width:`100%`}}
                      />
                      <Img
                        fluid={data.image5.childImageSharp.fluid}
                        alt="sca logo" 
                        style={{ width:`100%`}}
                      />
                      <Img
                        fluid={data.image6.childImageSharp.fluid}
                        alt="sca logo" 
                        style={{ width:`100%`}}
                      />
                      <Img
                        fluid={data.image7.childImageSharp.fluid}
                        alt="sca logo" 
                        style={{ width:`100%`}}
                      />
                      <Img
                        fluid={data.image8.childImageSharp.fluid}
                        alt="sca logo" 
                        style={{ width:`100%`}}
                      />
            </ImageWrapper>            


    </div>
    </Content>
    </Parallax>
    </Layout>
        )
    
}

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  // default mobile
  let breakpoints = {
    pages: 3.6, 
    contentOffset: 1.1, 
    contentFactor: 1, 
    width: width
  };

  if(exists(window)){
    useEffect(() => {

      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    });
  
    switch (true) {
      case (width >= 768):
        breakpoints.pages = 1.8;
  
        breakpoints.contentOffset = 0.34; 
        breakpoints.contentFactor = 1; 
        break;
    }
  }
  
 

  return breakpoints;
}

export default ecommerce 



